// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-fleet-child-page-js": () => import("/opt/build/repo/cnr-gatsby/src/templates/fleet-child-page.js" /* webpackChunkName: "component---src-templates-fleet-child-page-js" */),
  "component---src-templates-service-child-page-js": () => import("/opt/build/repo/cnr-gatsby/src/templates/service-child-page.js" /* webpackChunkName: "component---src-templates-service-child-page-js" */),
  "component---src-templates-fleet-tags-js": () => import("/opt/build/repo/cnr-gatsby/src/templates/fleet-tags.js" /* webpackChunkName: "component---src-templates-fleet-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-child-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/blog-child.js" /* webpackChunkName: "component---src-pages-blog-child-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-fleet-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/our-fleet.js" /* webpackChunkName: "component---src-pages-our-fleet-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-workshop-and-equipment-js": () => import("/opt/build/repo/cnr-gatsby/src/pages/workshop-and-equipment.js" /* webpackChunkName: "component---src-pages-workshop-and-equipment-js" */)
}

